/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

.c-btn {
  display: inline-block; /* [1] */
  vertical-align: middle; /* [2] */
  font: inherit; /* [3] */
  text-align: center; /* [4] */
  margin: 0; /* [4] */
  cursor: pointer; /* [5] */
  padding: 0.5rem 1rem;
  transition: $global-transition;
  border-radius: $global-radius;
}

/* Style variants
   ========================================================================== */

.c-btn--primary {
  background-color: $color-link;

  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none; /* [4] */
    color: $color-white;
  }

  &:hover,
  &:focus {
    background-color: $color-hover;
  }
}

/* Size variants
   ========================================================================== */

.c-btn--small {
  padding: 0.25rem 0.5rem;
}

.c-btn--large {
  padding: 0.75rem 1.5rem;
}

.btn-more {
	position: relative;
	letter-spacing: 1px;
	color: $color-cta;
	text-decoration: none;
	padding: 5px 20px 6px 8px;
	border-radius: 3px;
	color: #000;

	&:hover {
		color: #000;
	}

	&:before {
		position: absolute;
		content: '';
		width: calc(100% - 28px);
		height: 1px;
		background: #000;
		left: 7px;
		bottom: 3px;
	}

	&:after {
		position: absolute;
		content: "";
		right: 6px;
		top: 11px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 4px 0 4px 9px;
		border-color: transparent transparent transparent #ec3131;
	}

	&--blue {
		color: $color-cta;

	 	&:hover {
			color: $color-cta;
		}

		&:before {
			background: $color-cta;
		}

		&:after {
			border-color: transparent transparent transparent $color-cta;
		}	
	}
}
