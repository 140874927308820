/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  border-top: 1px solid $color-border;
  font-size: 0.875rem;
  margin-top: 2em;
  padding: 1em;
	margin-right: auto;
	margin-left: auto;
	max-width: 1300px;

	&:after {
		display: none;
	}
}

.footer {
	position: relative;
	background: #000 url(../assets/images/header.jpg) no-repeat;
	background-size: cover;
	width: 100%;
	min-height: 200px;
	color: #fff;

	&-heading {    
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		text-align: center;
	}
}

.partners {
	p {
    color: #666;
    letter-spacing: 10px;
    font-size: 11px;
		text-align: center;
	}

	.banner { 
		display: block;
		max-width: 728px;
		height: auto;
	}
}

.partners {
	display: none;
}

body.home.page-template-default .partners {
	display: flex;
	justify-content: center;
	margin-bottom: 10px; 
}
