/* ==========================================================================
   #HEADER
   ========================================================================== */

.logo {
  display: block;
  background: url(../assets/images/logo.png) no-repeat;
  width: 150px;
  height: 46px;
  background-size: 100% auto;
	margin-right: 20px;
}

.c-header {
  padding: 0.8em 0;
  text-align: center;
  background: #292929;

  &-content {
    padding: 0 15px;

    @include bp-medium {
      display: flex;
      justify-content: center;
    }
  }

  a {
  	font-family: $font-primary;
  	font-size: 15px;
  	color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;

    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    display: flex;
    margin-bottom: 0;
    margin-left: 0;
    list-style-type: none;
		flex-direction: column;
    justify-content: center;
    width: 100%;
		padding-top: 6px;

    li {
      margin-bottom: 6px;
    }

		li a {
			font-size: 12px;
		}

		@include bp-small {
			li a {
				font-size: 16px;
			}
		}

		@include bp-medium {
			flex-direction: row;
			padding-top: 0;

			li {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 0;
				margin-right: 25px; 
			}
		}
  }
}

.main-nav {
	display: flex;
	align-items: center;
}

.bar {
  font-family: $font-primary;
	background: #fafafa;
  padding: 15px;
  font-size: 18px;
	border-bottom: 1px solid #999;

  &-content {
    display: flex;
    justify-content: space-between;
    height: 65px;
    align-items: center;
  }

  a {
    text-decoration: none;
  }

  p {
    margin: 0;
  }
}

.cta-button, {
  display: block;
  background: $color-cta;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  min-width: 160px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border: 0;
  text-transform: uppercase;
  border-radius: 3px;
}

#cta-button {
  @extend .cta-button;
}

.goUp {
	display: none;

  @include bp-small {
		display: block;
    position: fixed;
    right: 20px;
    bottom: 20px;
    font-size: 24px;
    z-index: 2;
    cursor: pointer;
  }
}

.fa-facebook-square {
	color: #3b5998;
	margin-right: 10px;
	font-size: 14px;
	zoom: 0.8;

  @include bp-small {
		zoom: 100%;
	}
}

.fa-youtube {
	color: #f00;
	margin-right: 10px;
	font-size: 14px;
	zoom: 0.8;

  @include bp-small {
		zoom: 100%;
	}
}

.fa-phone-square {
	color: #da4040;
}

.header-tel {
	display: inline-flex;
	align-items: center;
	zoom: 0.8;

  @include bp-small {
		zoom: 100%;
	}

	.fa {
		margin-right: 5px;
	}
}
