input:not([type="submit"]),
textarea {
  width: 100%;
  border-radius: 3px;
  border: 1px solid $color-trans;
  resize: none;
  margin-bottom: 10px;
  padding: 4px;
}

textarea {
  width: 100%;
  height: 100px;
}

input[type="submit"] {
  margin: 0 auto;
}

.send-form {
  max-width: 240px;
  margin: 0 auto 40px;

  @include bp-medium {
    margin: 0 auto;
  }

	br {
		display: none;
	}

	span[role="alert"] {
		margin-bottom: 15px;
	}

	p {
		margin-bottom: 0;
	}

	div.wpcf7-response-output {
		margin: 0;
	}
}

