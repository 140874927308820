.section-frame {
	h2,
	h3 {
			padding: 10px;
			background: rgba(255,255,255,0.9);
			border-radius: 6px;
			text-align: center;
	}

	.section-content {
		div {
			padding: 16px;
			background: rgba(255,255,255,0.9);
			border-radius: 6px;

			& + .o-table {
				background: rgba(255,255,255,0.9);
				padding: 16px;
				margin-top: -15px;
			}
		}
	}
}

.section {
	position: relative;

	@include bp-medium {
		min-height: 500px;
	}

	&.pattern {
    background: url(../assets/images/pattern.jpg) no-repeat;
	}

	.section-wrapper {
		padding: 60px 18px;	

		h2 {
			text-align: center;
		}
	}

	.section-list {
		display: flex;
		justify-content: space-around;
		padding-top: 40px;
		text-align: center; 
		flex-direction: column;
		align-items: center;

		@include bp-medium {
			flex-direction: row;
		}

		.section-list-icon {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100px;
			height: 100px;
			border-radius: 50%;
			background: #da4040;
			color: #fff;
		}

		> div {
			display: flex;
			flex-direction: column;
			align-items: center;
			max-width: 200px;
			min-width: 200px;
			margin-bottom: 20px;

			h4 {
				margin-bottom: 5px;
			}

			> div {
				margin-bottom: 20px;
			}
		}
	}

	.section-content {
		padding: 64px 18px;

    @include bp-medium {
			position: absolute;
			max-width: 450px;
      width: auto;
      left: 60px;
			top: 50%;
      transform: translate(0,-50%);
    }

		&.set-right {

      @include bp-medium {
        width: auto;
        left: auto;
        right: 60px;
        text-align: right;
        transform: translate(0,-50%);
      }
		}

		&.set-center {
			text-align: center;

      @include bp-medium {
        width: auto;
				left: 50%;
				transform: translate(-50%,-50%);
      }
		}
	}

	&-zoom {
		background: #c0d6ce;
    background: -moz-linear-gradient(left, #c0d6ce 30%, #86a6a3 100%);
    background: -webkit-linear-gradient(left, #c0d6ce 30%,#86a6a3 100%);
    background: linear-gradient(to right, #c0d6ce 30%,#86a6a3 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c0d6ce', endColorstr='#86a6a3',GradientType=1 );

    @include bp-medium {
      background: #bfd6ce url(../assets/images/zoom.jpg) no-repeat top right;
      background-size: auto 100%;
    }
	}

	&-wall {
		background:#fcfcfc;
		background-size: auto 100%;

    @include bp-medium {
      background:#fcfcfc url(../assets/images/wall.jpg) no-repeat top right;
    }
	}

	&-pipe {
    background: url(../assets/images/pipe.jpg) no-repeat left 280px;
    background-size: 90% auto;

    @include bp-medium {
      background: url(../assets/images/pipe.jpg) no-repeat left 160px;
      background-size: 90% auto;
    }
 
    @include bp-large {
      background: url(../assets/images/pipe.jpg) no-repeat 0 -20px;
      background-size: 100% auto;
			min-height: 600px;
    }
	}

  &-plumber {
    background: url(../assets/images/plumber.jpg) no-repeat top right;
    background-size: auto 100%;

		.section-content {
			max-width: 550px;
		}

    @include bp-large {
			background-size: 100% auto;
		}
  }

  &-contact-tel {
    background: url(../assets/images/pattern.jpg) repeat center;
    min-height: 300px;
    color: #000;

    a,
    p {
      font-size: 20px;
      color: #000;
      text-decoration: none;
    }
  }

  &-contact {
    min-height: 600px;
    background: url(../assets/images/pattern.jpg) repeat center;

    @include bp-medium {
      min-height: 500px;

      .section-content {
        width: 600px !important;
        max-width: 620px;
        display: flex;
        justify-content: space-between;
      }
    }
  }

	&-mold-wall {
    background: url(../assets/images/mold-wall.jpg) repeat center;

    @include bp-medium {
			min-height: 480px;
		}
	}

	&-measure {
    background: url(../assets/images/measure.jpg) repeat center;

    @include bp-medium {
			min-height: 480px;
		}
	}

	&-flood {
    background: url(../assets/images/flood.jpg) repeat center;

    @include bp-medium {
			min-height: 480px;
		}
	}

	&-flood2 {
    background: url(../assets/images/flood2.jpg) repeat center;

    @include bp-medium {
			min-height: 480px;
		}
	}

	&-renovation {
    background: url(../assets/images/renovation.jpg) repeat center;

    @include bp-medium {
			min-height: 480px;
		}
	}
 
	&-ozone { 
    background: url(../assets/images/ozone.jpg) repeat top center; 
		background-size: cover;

    @include bp-medium {
			min-height: 480px;
		}
	}
 
	&-ozone2 { 
    background: url(../assets/images/ozone2.jpg) repeat top left; 
		background-size: cover;

    @include bp-medium {
			min-height: 480px;
		}
	}

	&-inspection {
    background: url(../assets/images/inspection.jpg) no-repeat top center; 

    @include bp-medium {
			min-height: 480px;
		}
	}

	&-inspection2 {
    background: url(../assets/images/inspection2.jpg) no-repeat top right; 

    @include bp-medium {
			min-height: 480px;
		}
	} 

	&-inspection3 {
    background: url(../assets/images/inspection3.jpg) no-repeat top center; 

    @include bp-medium {
			min-height: 480px;
		}
	}
 
	&-leak {
    background: url(../assets/images/leak.jpg) no-repeat center; 

    @include bp-medium {
			min-height: 480px;
		}
	}

	&-moisture {
    background: url(../assets/images/moisture.jpg) no-repeat top center; 

    @include bp-medium {
			min-height: 480px;
		}
	}

	&-marten {
    background: url(../assets/images/marten.jpg) no-repeat top center; 

    @include bp-medium {
			min-height: 480px;
		}
	}

	&-marten2 {
    background: url(../assets/images/marten2.jpg) no-repeat top center; 

    @include bp-medium {
			min-height: 480px;
		}
	}

	&-woman {
    background: #fcfcfc url(../assets/images/woman.jpg) no-repeat bottom left; 

    @include bp-medium {
			min-height: 480px;
		}
	}

	&-woman-reversed {
    background: #fcfcfc url(../assets/images/woman-reversed.jpg) no-repeat bottom right; 

    @include bp-medium {
			min-height: 480px;
		}
	}
}

.section:not(.section-contact):not(.pattern) {
	@extend .section-frame;

	@include bp-medium {
		h2,
		h3,
		.section-content div {
			padding: 0;
			background: transparent;
			text-align: inherit;
		}
	}
}

.section.section-flood,
.section.section-flood2,
.section.section-renovation {
	@include bp-medium {
		h2,
		h3 {
				padding: 10px !important;
				background: rgba(255,255,255,0.9) !important;
				border-radius: 6px !important;
				text-align: center !important;
		}

		.section-content {
			div {
				padding: 16px !important;
				background: rgba(255,255,255,0.9) !important;
				border-radius: 6px !important;
			}
		}
	}
}

.tel {
  font-size: 20px;
  color: #000;
  text-decoration: none;
} 

.address-section {
	display: flex;
	flex-direction: column;
	justify-content: center;
  font-size: 20px;

  h3 {
    margin-bottom: 10px;
  }

  h3:not(:first-child) {
    margin-top: 20px;
  }
}

.section.section-gallery {
	min-height: 380px;

	.section-wrapper {
		background: #fefefe;

		h2 {
			text-align: center;
			margin-bottom: 40px;
		}

		ul {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			list-style-type: none;

			li {
				margin: 0 20px 0 0;
			}
		}
	}
}
