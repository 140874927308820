@charset "UTF-8";
/*
  Project: Lakus
  Author: Krystian
 */
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.3rem;
}

/**
 * Consistent indentation for lists.
 */
dd, ol, ul {
  margin-left: 1rem;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  quotes: '“' '”' '‘' '’';
  font-style: italic;
}

blockquote p:first-child:before {
  content: open-quote;
}

blockquote p:last-child:after {
  content: close-quote;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Oswald, sans-serif;
  font-weight: 500;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
html {
  background: #fff;
  color: #000;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Roboto", "Helvetica Neue", "Arial", sans-serif;
  font-size: 0.8125rem;
  /* [1] */
  /* 13px */
  line-height: 1.5;
  /* [1] */
  min-height: 100%;
  /* [3] */
  overflow-y: scroll;
  /* [2] */
}

@media (min-width: 768px) {
  html {
    font-size: 0.875em;
    /* 14px */
  }
}

@media (min-width: 1024px) {
  html {
    font-size: 0.9375em;
    /* 15px */
  }
}

@media (min-width: 1600px) {
  html {
    font-size: 1em;
    /* 16px */
  }
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic;
  /* [2] */
  max-width: 100%;
  /* [1] */
  vertical-align: middle;
  /* [3] */
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
img[width],
img[height] {
  max-width: none;
}

i {
  margin-right: 2px;
}

input:not([type="submit"]),
textarea {
  width: 100%;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  resize: none;
  margin-bottom: 10px;
  padding: 4px;
}

textarea {
  width: 100%;
  height: 100px;
}

input[type="submit"] {
  margin: 0 auto;
}

.send-form {
  max-width: 240px;
  margin: 0 auto 40px;
}

@media (min-width: 768px) {
  .send-form {
    margin: 0 auto;
  }
}

.send-form br {
  display: none;
}

.send-form span[role="alert"] {
  margin-bottom: 15px;
}

.send-form p {
  margin-bottom: 0;
}

.send-form div.wpcf7-response-output {
  margin: 0;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: #666;
  text-decoration: underline;
}

a:hover {
  color: #999;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
}

@media (min-width: 768px) {
  .o-layout {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;
}

.o-layout--guttered {
  justify-content: space-between;
}

.o-layout--guttered > .o-layout__item {
  flex: none;
}

@media (min-width: 768px) {
  .o-layout--2 > .o-layout__item {
    width: 49%;
  }
}

@media (min-width: 768px) {
  .o-layout--3 > .o-layout__item {
    width: 32%;
  }
}

@media (min-width: 768px) {
  .o-layout--4 > .o-layout__item {
    width: 24%;
  }
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}

.o-media:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}

.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}

.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0.5);
}

.o-table td {
  border: 2px solid rgba(0, 0, 0, 0.5);
  padding: 4px 6px;
  background: rgba(255, 255, 255, 0.5);
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 1300px;
}

.o-wrapper:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */
/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */
.c-btn {
  display: inline-block;
  /* [1] */
  vertical-align: middle;
  /* [2] */
  font: inherit;
  /* [3] */
  text-align: center;
  /* [4] */
  margin: 0;
  /* [4] */
  cursor: pointer;
  /* [5] */
  padding: 0.5rem 1rem;
  transition: all 300ms ease-in-out;
  border-radius: 3px;
}

/* Style variants
   ========================================================================== */
.c-btn--primary {
  background-color: #666;
}

.c-btn--primary, .c-btn--primary:hover, .c-btn--primary:active, .c-btn--primary:focus {
  text-decoration: none;
  /* [4] */
  color: #fff;
}

.c-btn--primary:hover, .c-btn--primary:focus {
  background-color: #999;
}

/* Size variants
   ========================================================================== */
.c-btn--small {
  padding: 0.25rem 0.5rem;
}

.c-btn--large {
  padding: 0.75rem 1.5rem;
}

.btn-more {
  position: relative;
  letter-spacing: 1px;
  color: #19b5fe;
  text-decoration: none;
  padding: 5px 20px 6px 8px;
  border-radius: 3px;
  color: #000;
}

.btn-more:hover {
  color: #000;
}

.btn-more:before {
  position: absolute;
  content: '';
  width: calc(100% - 28px);
  height: 1px;
  background: #000;
  left: 7px;
  bottom: 3px;
}

.btn-more:after {
  position: absolute;
  content: "";
  right: 6px;
  top: 11px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 9px;
  border-color: transparent transparent transparent #ec3131;
}

.btn-more--blue {
  color: #19b5fe;
}

.btn-more--blue:hover {
  color: #19b5fe;
}

.btn-more--blue:before {
  background: #19b5fe;
}

.btn-more--blue:after {
  border-color: transparent transparent transparent #19b5fe;
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  border-top: 1px solid #ccc;
  font-size: 0.875rem;
  margin-top: 2em;
  padding: 1em;
  margin-right: auto;
  margin-left: auto;
  max-width: 1300px;
}

.c-footer:after {
  display: none;
}

.footer {
  position: relative;
  background: #000 url(../assets/images/header.jpg) no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 200px;
  color: #fff;
}

.footer-heading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.partners p {
  color: #666;
  letter-spacing: 10px;
  font-size: 11px;
  text-align: center;
}

.partners .banner {
  display: block;
  max-width: 728px;
  height: auto;
}

.partners {
  display: none;
}

body.home.page-template-default .partners {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
.logo {
  display: block;
  background: url(../assets/images/logo.png) no-repeat;
  width: 150px;
  height: 46px;
  background-size: 100% auto;
  margin-right: 20px;
}

.c-header {
  padding: 0.8em 0;
  text-align: center;
  background: #292929;
}

.c-header-content {
  padding: 0 15px;
}

@media (min-width: 768px) {
  .c-header-content {
    display: flex;
    justify-content: center;
  }
}

.c-header a {
  font-family: Oswald, sans-serif;
  font-size: 15px;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.c-header a:hover {
  text-decoration: underline;
}

.c-header ul {
  display: flex;
  margin-bottom: 0;
  margin-left: 0;
  list-style-type: none;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-top: 6px;
}

.c-header ul li {
  margin-bottom: 6px;
}

.c-header ul li a {
  font-size: 12px;
}

@media (min-width: 480px) {
  .c-header ul li a {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .c-header ul {
    flex-direction: row;
    padding-top: 0;
  }
  .c-header ul li {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    margin-right: 25px;
  }
}

.main-nav {
  display: flex;
  align-items: center;
}

.bar {
  font-family: Oswald, sans-serif;
  background: #fafafa;
  padding: 15px;
  font-size: 18px;
  border-bottom: 1px solid #999;
}

.bar-content {
  display: flex;
  justify-content: space-between;
  height: 65px;
  align-items: center;
}

.bar a {
  text-decoration: none;
}

.bar p {
  margin: 0;
}

.cta-button, #cta-button {
  display: block;
  background: #19b5fe;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  min-width: 160px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border: 0;
  text-transform: uppercase;
  border-radius: 3px;
}

.goUp {
  display: none;
}

@media (min-width: 480px) {
  .goUp {
    display: block;
    position: fixed;
    right: 20px;
    bottom: 20px;
    font-size: 24px;
    z-index: 2;
    cursor: pointer;
  }
}

.fa-facebook-square {
  color: #3b5998;
  margin-right: 10px;
  font-size: 14px;
  zoom: 0.8;
}

@media (min-width: 480px) {
  .fa-facebook-square {
    zoom: 100%;
  }
}

.fa-youtube {
  color: #f00;
  margin-right: 10px;
  font-size: 14px;
  zoom: 0.8;
}

@media (min-width: 480px) {
  .fa-youtube {
    zoom: 100%;
  }
}

.fa-phone-square {
  color: #da4040;
}

.header-tel {
  display: inline-flex;
  align-items: center;
  zoom: 0.8;
}

@media (min-width: 480px) {
  .header-tel {
    zoom: 100%;
  }
}

.header-tel .fa {
  margin-right: 5px;
}

.section-frame h2, .section:not(.section-contact):not(.pattern) h2,
.section-frame h3,
.section:not(.section-contact):not(.pattern) h3 {
  padding: 10px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 6px;
  text-align: center;
}

.section-frame .section-content div, .section:not(.section-contact):not(.pattern) .section-content div {
  padding: 16px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 6px;
}

.section-frame .section-content div + .o-table, .section:not(.section-contact):not(.pattern) .section-content div + .o-table {
  background: rgba(255, 255, 255, 0.9);
  padding: 16px;
  margin-top: -15px;
}

.section {
  position: relative;
}

@media (min-width: 768px) {
  .section {
    min-height: 500px;
  }
}

.section.pattern {
  background: url(../assets/images/pattern.jpg) no-repeat;
}

.section .section-wrapper {
  padding: 60px 18px;
}

.section .section-wrapper h2 {
  text-align: center;
}

.section .section-list {
  display: flex;
  justify-content: space-around;
  padding-top: 40px;
  text-align: center;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 768px) {
  .section .section-list {
    flex-direction: row;
  }
}

.section .section-list .section-list-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #da4040;
  color: #fff;
}

.section .section-list > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 200px;
  min-width: 200px;
  margin-bottom: 20px;
}

.section .section-list > div h4 {
  margin-bottom: 5px;
}

.section .section-list > div > div {
  margin-bottom: 20px;
}

.section .section-content {
  padding: 64px 18px;
}

@media (min-width: 768px) {
  .section .section-content {
    position: absolute;
    max-width: 450px;
    width: auto;
    left: 60px;
    top: 50%;
    transform: translate(0, -50%);
  }
}

@media (min-width: 768px) {
  .section .section-content.set-right {
    width: auto;
    left: auto;
    right: 60px;
    text-align: right;
    transform: translate(0, -50%);
  }
}

.section .section-content.set-center {
  text-align: center;
}

@media (min-width: 768px) {
  .section .section-content.set-center {
    width: auto;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.section-zoom {
  background: #c0d6ce;
  background: -moz-linear-gradient(left, #c0d6ce 30%, #86a6a3 100%);
  background: -webkit-linear-gradient(left, #c0d6ce 30%, #86a6a3 100%);
  background: linear-gradient(to right, #c0d6ce 30%, #86a6a3 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c0d6ce', endColorstr='#86a6a3',GradientType=1 );
}

@media (min-width: 768px) {
  .section-zoom {
    background: #bfd6ce url(../assets/images/zoom.jpg) no-repeat top right;
    background-size: auto 100%;
  }
}

.section-wall {
  background: #fcfcfc;
  background-size: auto 100%;
}

@media (min-width: 768px) {
  .section-wall {
    background: #fcfcfc url(../assets/images/wall.jpg) no-repeat top right;
  }
}

.section-pipe {
  background: url(../assets/images/pipe.jpg) no-repeat left 280px;
  background-size: 90% auto;
}

@media (min-width: 768px) {
  .section-pipe {
    background: url(../assets/images/pipe.jpg) no-repeat left 160px;
    background-size: 90% auto;
  }
}

@media (min-width: 1024px) {
  .section-pipe {
    background: url(../assets/images/pipe.jpg) no-repeat 0 -20px;
    background-size: 100% auto;
    min-height: 600px;
  }
}

.section-plumber {
  background: url(../assets/images/plumber.jpg) no-repeat top right;
  background-size: auto 100%;
}

.section-plumber .section-content {
  max-width: 550px;
}

@media (min-width: 1024px) {
  .section-plumber {
    background-size: 100% auto;
  }
}

.section-contact-tel {
  background: url(../assets/images/pattern.jpg) repeat center;
  min-height: 300px;
  color: #000;
}

.section-contact-tel a,
.section-contact-tel p {
  font-size: 20px;
  color: #000;
  text-decoration: none;
}

.section-contact {
  min-height: 600px;
  background: url(../assets/images/pattern.jpg) repeat center;
}

@media (min-width: 768px) {
  .section-contact {
    min-height: 500px;
  }
  .section-contact .section-content {
    width: 600px !important;
    max-width: 620px;
    display: flex;
    justify-content: space-between;
  }
}

.section-mold-wall {
  background: url(../assets/images/mold-wall.jpg) repeat center;
}

@media (min-width: 768px) {
  .section-mold-wall {
    min-height: 480px;
  }
}

.section-measure {
  background: url(../assets/images/measure.jpg) repeat center;
}

@media (min-width: 768px) {
  .section-measure {
    min-height: 480px;
  }
}

.section-flood {
  background: url(../assets/images/flood.jpg) repeat center;
}

@media (min-width: 768px) {
  .section-flood {
    min-height: 480px;
  }
}

.section-flood2 {
  background: url(../assets/images/flood2.jpg) repeat center;
}

@media (min-width: 768px) {
  .section-flood2 {
    min-height: 480px;
  }
}

.section-renovation {
  background: url(../assets/images/renovation.jpg) repeat center;
}

@media (min-width: 768px) {
  .section-renovation {
    min-height: 480px;
  }
}

.section-ozone {
  background: url(../assets/images/ozone.jpg) repeat top center;
  background-size: cover;
}

@media (min-width: 768px) {
  .section-ozone {
    min-height: 480px;
  }
}

.section-ozone2 {
  background: url(../assets/images/ozone2.jpg) repeat top left;
  background-size: cover;
}

@media (min-width: 768px) {
  .section-ozone2 {
    min-height: 480px;
  }
}

.section-inspection {
  background: url(../assets/images/inspection.jpg) no-repeat top center;
}

@media (min-width: 768px) {
  .section-inspection {
    min-height: 480px;
  }
}

.section-inspection2 {
  background: url(../assets/images/inspection2.jpg) no-repeat top right;
}

@media (min-width: 768px) {
  .section-inspection2 {
    min-height: 480px;
  }
}

.section-inspection3 {
  background: url(../assets/images/inspection3.jpg) no-repeat top center;
}

@media (min-width: 768px) {
  .section-inspection3 {
    min-height: 480px;
  }
}

.section-leak {
  background: url(../assets/images/leak.jpg) no-repeat center;
}

@media (min-width: 768px) {
  .section-leak {
    min-height: 480px;
  }
}

.section-moisture {
  background: url(../assets/images/moisture.jpg) no-repeat top center;
}

@media (min-width: 768px) {
  .section-moisture {
    min-height: 480px;
  }
}

.section-marten {
  background: url(../assets/images/marten.jpg) no-repeat top center;
}

@media (min-width: 768px) {
  .section-marten {
    min-height: 480px;
  }
}

.section-marten2 {
  background: url(../assets/images/marten2.jpg) no-repeat top center;
}

@media (min-width: 768px) {
  .section-marten2 {
    min-height: 480px;
  }
}

.section-woman {
  background: #fcfcfc url(../assets/images/woman.jpg) no-repeat bottom left;
}

@media (min-width: 768px) {
  .section-woman {
    min-height: 480px;
  }
}

.section-woman-reversed {
  background: #fcfcfc url(../assets/images/woman-reversed.jpg) no-repeat bottom right;
}

@media (min-width: 768px) {
  .section-woman-reversed {
    min-height: 480px;
  }
}

@media (min-width: 768px) {
  .section:not(.section-contact):not(.pattern) h2,
  .section:not(.section-contact):not(.pattern) h3,
  .section:not(.section-contact):not(.pattern) .section-content div {
    padding: 0;
    background: transparent;
    text-align: inherit;
  }
}

@media (min-width: 768px) {
  .section.section-flood h2,
  .section.section-flood h3,
  .section.section-flood2 h2,
  .section.section-flood2 h3,
  .section.section-renovation h2,
  .section.section-renovation h3 {
    padding: 10px !important;
    background: rgba(255, 255, 255, 0.9) !important;
    border-radius: 6px !important;
    text-align: center !important;
  }
  .section.section-flood .section-content div,
  .section.section-flood2 .section-content div,
  .section.section-renovation .section-content div {
    padding: 16px !important;
    background: rgba(255, 255, 255, 0.9) !important;
    border-radius: 6px !important;
  }
}

.tel {
  font-size: 20px;
  color: #000;
  text-decoration: none;
}

.address-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
}

.address-section h3 {
  margin-bottom: 10px;
}

.address-section h3:not(:first-child) {
  margin-top: 20px;
}

.section.section-gallery {
  min-height: 380px;
}

.section.section-gallery .section-wrapper {
  background: #fefefe;
}

.section.section-gallery .section-wrapper h2 {
  text-align: center;
  margin-bottom: 40px;
}

.section.section-gallery .section-wrapper ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style-type: none;
}

.section.section-gallery .section-wrapper ul li {
  margin: 0 20px 0 0;
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}

@media (min-width: 768px) {
  .u-hidden\@small {
    display: block;
  }
}
